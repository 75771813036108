import React, { useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import { addDepartmentPosition, createDepartment } from 'api/department'
import { getEmployees } from 'api/employees'
import { getPositions } from 'api/position'
import {
  parseDataToDynamicPositions,
  parseDataToEmployees,
} from 'utils/parseData'
import PositionInput from 'components/settings/Tabs/Department/EditDepartment/PositionInput'
import { IDynamicPosition } from 'components/settings/Tabs/Department/EditDepartment'
import { RequiredFields } from 'constants/requiredFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import debounce from 'lodash/debounce'
import { Autocomplete, Box, TextField, useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { ModalTitles } from 'constants/modalTitles'
import { ButtonTypes } from 'constants/buttonTypes'
import BaseButton from 'components/ui/buttons/BaseButton'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  updateDepartments?: () => void
}

interface ICreateDirector {
  value: number
  item: string
}

interface ICreateDepartment {
  departmentName: string | null
  director: ICreateDirector | null
  positions: number[]
}

const inputName = 'departmentName'
const directorInputName = 'director'

const CreateDepartment = ({
  show,
  onClose,
  showEndModal,
  updateDepartments,
}: Props) => {
  const [errorText, setErrorText] = useState<string>(
    'Не заполнены обязательные поля'
  )

  const defaultPositionValue = {
    value: `new-0`,
    item: '',
    new: true,
  }

  const [listEmployee, setListEmployee] = useState<Array<IItem>>([])
  const [allPositions, setAllPositions] = useState<IDynamicPosition[]>([])
  const [positions, setPositions] = useState<IDynamicPosition[]>([
    defaultPositionValue,
  ])
  const [newPositionCount, setNewPositionCount] = useState<number>(1)
  const [selectedUser, setSelectedUser] = useState<IItem | null>(null)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const [tick, setTick] = useState(1)

  const updateTick = () => {
    setTick(tick + 1)
  }

  const fetchLists = async () => {
    await getEmployees()
      .then((data) => {
        setListEmployee(parseDataToEmployees(data.data))
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((err) => handlerError(err))
    await getPositions()
      .then((data) => setAllPositions(parseDataToDynamicPositions(data)))
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik<ICreateDepartment>({
    initialValues: {
      departmentName: null,
      director: null,
      positions: [],
    },
    onSubmit: (data) => {
      const newPositions = positions
        .map((item) => String(item.value))
        .filter((item) => item && !item.includes('new'))

      createDepartment(
        values.departmentName ?? '',
        data.director?.value ?? 0,
        newPositions
      )
        .then(() => {
          if (updateDepartments) {
            updateDepartments()
          }
        })
        .catch((err) => handlerError(err))
      handleClose()
      resetFullForm()

      if (showEndModal) {
        showEndModal(true)
      }
    },
    validationSchema: Yup.object().shape({
      departmentName: Yup.string().required(RequiredFields.departmentName),
      director: Yup.object().required(RequiredFields.departmentDirector),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const resetFullForm = () => {
    setPositions([])
    resetForm()
  }

  const handleClose = (): void => {
    onClose(!show)
  }

  useEffect(() => {
    fetchLists().then()
  }, [])

  const isValid = useMemo(
    () =>
      values.departmentName &&
      values.director?.value &&
      values.director?.value !== -1 &&
      positions.length &&
      positions.filter((p) => p.item.length !== 0).length > 0,
    [values.director?.value, values.departmentName, positions, tick]
  )

  useEffect(() => {
    setBtnDisabled(!isValid)
  }, [isValid])

  const removePosition = (indexToRemove: number) => {
    const position = positions[indexToRemove]

    if (position.new) {
      setPositions(positions.filter((_, index) => index !== indexToRemove))
    }
  }

  const createPosition = () => {
    setPositions([
      ...positions,
      {
        value: `new-${newPositionCount}`,
        item: '',
        new: true,
      },
    ])
    setNewPositionCount((prevState) => prevState + 1)
  }

  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close
            onClick={() => {
              handleClose()
              resetFullForm()
            }}
          >
            <Close />
          </ST.Close>
          <ST.ModalTitle>{ModalTitles.departmentCreate}</ST.ModalTitle>
          <ST.InputsBlock>
            <ST.InputWrapper>
              <ST.Label>
                Название отдела<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Input
                placeholder={''}
                sizeInput={InputSizes.big}
                id={inputName}
                name={inputName}
                value={values.departmentName ?? ''}
                onChange={handleChange}
              />
            </ST.InputWrapper>
            <ST.SelectWrapper>
              <ST.Label>
                Руководитель отдела<ST.Star>*</ST.Star>
              </ST.Label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                clearOnBlur={false}
                blurOnSelect={true}
                noOptionsText={'Нет доступных вариантов'}
                options={filterUserList}
                style={
                  isMobileDevice
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        boxSizing: 'border-box',
                        borderRadius: '10px',
                        height: '48px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                      }
                    : {
                        color: '#000000',
                        backgroundColor: '#f8f8f8',
                        padding: '0',
                        marginBottom: '30px',
                        marginTop: '10px',
                        borderRadius: '10px',
                        height: '20px',
                        fontWeight: '400',
                        fontSize: '16px',
                        width: '100%',
                      }
                }
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '0 !important',
                  },
                  '& .Mui-focused.MuiFormLabel-root.MuiInputLabel-root': {
                    opacity: '0',
                  },
                  '& .MuiFormLabel-filled': {
                    opacity: '0 !important',
                  },
                  '& .MuiFormLabel-filled + div > fieldset > legend': {
                    display: 'none !important',
                  },
                  '& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: 'transparent',
                    },
                  '& .MuiFormLabel-root': {
                    top: '-8px',
                    color: '#606060',
                    opacity: '0.5',
                    fontFamily: 'Golos, sans-serif !important',
                  },
                  '& .MuiAutocomplete-input': {
                    ...(isMobileDevice
                      ? {
                          paddingLeft: '16px !important',
                          paddingRight: '16px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }
                      : {
                          paddingLeft: '10px !important',
                          fontFamily: 'Golos, sans-serif !important',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '140%',
                          color: 'black',
                        }),
                  },
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
                filterSelectedOptions
                value={values.director}
                onInputChange={debounce(
                  (e: any) => handleInputChange(e.target.value),
                  1000
                )}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option?.value === value?.value
                }}
                filterOptions={(options: any) => options}
                getOptionLabel={(option: any) => option.item}
                onChange={(event, value: any) => {
                  if (value) {
                    setFieldValue(directorInputName, value)
                    setSelectedUser(value)
                  } else {
                    setFieldValue(directorInputName, { item: '', value: -1 })
                    setSelectedUser(null)
                  }
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <Box
                      component="li"
                      style={{
                        backgroundColor: '#fefefe',
                        border: 'none',
                        color: '#898989',
                      }}
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      key={option.value}
                    >
                      {option.item}
                    </Box>
                  )
                }}
                renderInput={(params: any) => (
                  <TextField
                    style={{
                      backgroundColor: '#f8f8f8',
                      borderWidth: 0,
                      '::placeholder': {
                        opacity: '0.5',
                      },
                      borderRadius: '10px',
                    }}
                    {...params}
                    label="Руководитель"
                  />
                )}
              />
            </ST.SelectWrapper>
            <ST.PositionsBlock>
              <ST.Label>
                Должность<ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Positions>
                {positions.map((item, index, array) => (
                  <ST.PositionWrapper key={String(item.value)}>
                    <PositionInput
                      value={item}
                      placeholder={Placeholders.choosePosition}
                      listItems={allPositions}
                      selectedItems={positions}
                      disabledDelete={array.length === 1}
                      onChange={(newValue) => {
                        const newPositions = positions
                        if (newValue) {
                          newPositions[index] = newValue
                        } else {
                          newPositions[index] = defaultPositionValue
                        }
                        setPositions(newPositions)
                        updateTick()
                      }}
                      onRemove={() => removePosition(index)}
                    />
                  </ST.PositionWrapper>
                ))}
              </ST.Positions>
            </ST.PositionsBlock>
            <ST.AddPlaceButton onClick={createPosition}>
              <ST.Plus /> Добавить должность
            </ST.AddPlaceButton>
          </ST.InputsBlock>
          <ST.OutputBlock>
            <ST.ErrorText>
              {errors.departmentName ||
              errors.director ||
              values.director?.value === -1
                ? errorText
                : ''}
            </ST.ErrorText>
          </ST.OutputBlock>
          <ST.CreateButton>
            <BaseButton
              type={'submit'}
              text={'Создать'}
              typeButton={ButtonTypes.primary}
              onClick={checkForm}
              fakeDisabled={btnDisabled}
            />
          </ST.CreateButton>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateDepartment
