import React, { FC } from 'react'
import { EmptyResult as ST } from './styled'
import { EmptyResultTypes as T } from './types'
import { ReactComponent as NotFound } from 'assets/images/notFound.svg'
import BaseButton from 'components/ui/buttons/BaseButton'
import EmptyResultStrings from 'constants/EmptyResult'

const defaultButtonSize = {
  width: 277,
  height: 50,
}

const EmptyResult: FC<T.Props> = ({
  icon,
  title = EmptyResultStrings.TITLE,
  description = EmptyResultStrings.DESCRIPTION,
  orientation = 'vertical',
  style,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <ST.Content orientation={orientation} style={style}>
      <ST.NotFoundContainer
        imageAlign={
          title || description
            ? orientation === 'vertical'
              ? 'center'
              : 'right'
            : 'center'
        }
      >
        {icon ?? <NotFound />}
      </ST.NotFoundContainer>
      {(title || description) && (
        <ST.TextContainer>
          {title && <ST.Title>{title}</ST.Title>}
          {description && <ST.Description>{description}</ST.Description>}
        </ST.TextContainer>
      )}

      {(primaryButton || secondaryButton) && (
        <ST.ButtonsContainer>
          {primaryButton &&
            (!!primaryButton.href ? (
              <ST.LinkButton href={primaryButton.href}>
                {primaryButton.text}
              </ST.LinkButton>
            ) : (
              <BaseButton
                {...defaultButtonSize}
                text={primaryButton.text}
                onClick={primaryButton.action}
              />
            ))}
          {secondaryButton &&
            (!!secondaryButton.href ? (
              <ST.LinkButton href={secondaryButton.href} typeButton="secondary">
                {secondaryButton.text}
              </ST.LinkButton>
            ) : (
              <BaseButton
                {...defaultButtonSize}
                typeButton="secondary"
                text={secondaryButton.text}
                onClick={secondaryButton.action}
              />
            ))}
        </ST.ButtonsContainer>
      )}
    </ST.Content>
  )
}

export default EmptyResult
