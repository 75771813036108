export enum FileTypes {
  documents = 'application/pdf, .doc, .docx, .rtf, .xls, .xlsx, .ppt, .pptx',
  pdfAndImages = 'application/pdf, .png, .jpg, .jpeg',
  images = '.png, .jpg, .jpeg',
  excel = '.xls, .xlsx',
  default = 'application/pdf, .doc, .docx, .rtf, .xls, .xlsx, .pptx, .png, .jpg, .jpeg, .7z, .zip, .txt, .json',
}

export const FILE_FORMATS = [
  'jpg',
  'jpeg',
  'png',
  'doc',
  'docx',
  'pdf',
  'rtf',
  'xlsx',
  'xls',
  'pptx',
  '7z',
  'zip',
  'txt',
  'json',
]
