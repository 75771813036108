import { useCallback, useEffect, useMemo, useState } from 'react'

// Определяем тип для функции обновления, которая может принимать значение или функцию для его обновления
type SetValue<T> = T | ((val: T) => T)

type ReturnValue<T> = [value: T, setValue: SetState<T>]

// FIXME хук не возвращает число, если в дженерике передавать number
const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  initialValue?: T
): ReturnValue<T> => {
  // Используем функцию для инициализации состояния, чтобы избежать повторного вычисления начального значения
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (initialValue) return initialValue

      const item = window.localStorage.getItem(key)

      // Парсим значение из JSON или возвращаем defaultValue, если ничего нет
      return item ? JSON.parse(item) : defaultValue
    } catch (error) {
      console.error(error)
      return defaultValue
    }
  })

  // Определяем функцию для обновления значения как в localStorage, так и в состоянии
  const setValue = useCallback(
    (value: SetValue<T>) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        console.error(error)
      }
    },
    [key, storedValue]
  )

  // Эффект для синхронизации изменений с другими экземплярами хука (например, в других вкладках)
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        try {
          setStoredValue(
            event.newValue ? JSON.parse(event.newValue) : defaultValue
          )
        } catch (error) {
          console.error(error)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Удаляем слушатель, чтобы избежать утечек памяти
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, defaultValue])

  return useMemo(() => [storedValue, setValue], [setValue, storedValue])
}

export default useLocalStorage
