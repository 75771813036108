export enum ErrorMessages {
  incorrectEmail = 'Некорректный email',
  incorrectURL = 'Некорректная ссылка',
  twoSymbolsMin = 'Введите минимум 2 символа',
  fourSymbolMin = 'Минимальное кол-во символов – 4',
  tenSymbolMin = 'Минимальное кол-во символов – 10',
  sameEmail = 'Пользователь с таким email уже существует',
  noDepartments = 'Отделы отсутствуют',
  noPositions = 'В этом отделе нет должностей',
  noGrades = 'В этой должности нет грейдов',
  forbidden = 'Для продолжения необходимо авторизоваться',
  httpsDomain = 'URL должен начинаться с https:// или http:// и содержать доменное имя',
  incorrectPhoneFormat = 'Некорректный формат номера телефона',
  incorrectYear = 'Некорректный год',
  maxSymbolsLimit = 'Превышено максимальное количество символов',
  endLoading = 'Все данные загружены',
  incorrectDate = 'Некорректная дата',
  requiredField = 'Обязательное поле',
  positiveValue = 'Значение должно быть положительным',
}

export const DefaultErrorMessage = 'Произошла непредвиденная ошибка'
